<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> KPI Performance
      </span>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <h6 class="text-primary" v-if="resultKpiReference">
                  <b>{{ resultKpiReference.KpiDescription }}</b>
                </h6>
              </div>
              <div class="col-md-3 col">
                <h6 v-if="resultKpiPerformItems">
                  Store
                  <b class="text-primary">{{
                    resultKpiPerformItems.OutletName
                  }}</b>
                </h6>
              </div>
              <div class="col-md-3 col">
                <h6 v-if="resultKpiPerformItems">
                  Login Time
                  <b class="text-primary">{{
                    resultKpiPerformItems.LoginTime | formatDateTime
                  }}</b>
                </h6>
              </div>
              <div class="col-md-3 col">
                <h6 v-if="resultKpiPerformItems">
                  Leverage Code
                  <b class="text-primary">{{
                    resultKpiPerformItems.LeverageOutletCode
                  }}</b>
                </h6>
              </div>
              <div class="col-md-3 col">
                <h6 v-if="resultKpiPerformItems">
                  Store Name
                  <b class="text-primary">{{
                    resultKpiPerformItems.OutletName
                  }}</b>
                </h6>
              </div>
              <div class="col-md-3 col">
                <h6 v-if="resultKpiPerformItems">
                  CMIH Code
                  <b class="text-primary">{{
                    resultKpiPerformItems.OutletCode
                  }}</b>
                </h6>
              </div>
            </div>
            <hr />
            <div class="row" v-if="resultoutlet">
              <tabs tabNavClasses="nav-fill flex-column flex-sm-row">
                <card shadow>
                  <tab-pane icon="ni ni-tag" title="OSA">
                    <div class="description">
                      <div class="card bg-secondary">
                        <div class="card-header">
                          <h3 class="card-title">OSA KPI</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>SKU</label>
                                <select
                                  v-model="OsaProductIdval"
                                  class="form-control"
                                  @change="getproductcompetitor()"
                                  required
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in resultOsaItems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.ProductName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="OsaProductIdval > 0">
                              <div
                                class="card bg-light"
                                v-if="resultoutlet.ChannelId == 3"
                              >
                                <div class="card-header">SKU Competitor</div>
                                <div class="card-body">
                                  <div class="form-group">
                                    <label>Competitor</label>
                                    <select
                                      v-model="ProductCompetitorval"
                                      class="form-control"
                                      required
                                    >
                                      <option value="0"></option>
                                      <option
                                        v-for="item in resultProductCompetitorItems"
                                        :key="item.Id"
                                        v-bind:value="item.Id"
                                      >
                                        {{ item.CompetitorName }}
                                      </option>
                                    </select>
                                  </div>
                                  <div class="form-group">
                                    <label>Competitor Price</label>
                                    <input
                                      v-model="CompetitorProductPriceval"
                                      class="form-control text-right"
                                    />
                                  </div>
                                  <div class="card-footer text-center">
                                    <button
                                      class="btn btn-secondary"
                                      v-on:click="
                                        processkpiPerformanceproductcompetitivetemp()
                                      "
                                    >
                                      Add
                                    </button>
                                  </div>
                                  <div class="mt-3">
                                    <div class="table-responsive">
                                      <table
                                        class="table table-bordered table-hover"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Competitor</th>
                                            <th>Price</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="item in resultTempProductCompetitorItems"
                                            :key="item.Id"
                                          >
                                            <td>{{ item.CompetitorName }}</td>
                                            <td>{{ item.ProductPrice }}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Available Unit</label>
                                <input
                                  type="number"
                                  v-model="OsaProductUnitval"
                                  class="form-control"
                                />
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Store Display Price</label>
                                <input
                                  v-model="OsaProductPriceDisplayval"
                                  class="form-control text-right"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="card-footer text-center">
                            <button
                              class="btn btn-success"
                              v-on:click="processkpiperformancedetailsosa()"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-pane>
                  <tab-pane icon="ni ni-tag" title="NPD">
                    <div class="description">
                      <div class="card bg-secondary">
                        <div class="card-header">
                          <h3 class="card-title">Npd KPI</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>SKU</label>
                                <select
                                  v-model="NpdProductIdval"
                                  class="form-control"
                                  required
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in resultNpdItems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.ProductName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Available Unit</label>
                                <input
                                  type="number"
                                  v-model="NpdProductUnitval"
                                  class="form-control"
                                />
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Store Display Price</label>
                                <input
                                  v-model="NpdProductPriceDisplayval"
                                  class="form-control text-right"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="card-footer text-center">
                            <button
                              class="btn btn-success"
                              v-on:click="processkpiperformancedetailsnpd()"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-pane>
                  <tab-pane icon="ni ni-tag" title="Promo Compliance">
                    <div class="description">
                      <div class="card bg-secondary">
                        <div class="card-header">
                          <h3 class="card-title">Promo Compliance</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>SKU</label>
                                <select
                                  v-model="PromoProductIdval"
                                  class="form-control"
                                  required
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in resultPromoItems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.ProductName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <span>Is The Product Available</span>
                                <select
                                  v-model="IsPromoRuningval"
                                  class="form-control"
                                  required
                                >
                                  <option value="False"></option>
                                  <option value="True">Yes</option>
                                  <option value="False">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <span class="mr-4"
                                  >Is retailer aware of the product
                                  mechanics</span
                                >
                                <select
                                  v-model="IsRetailerAwareval"
                                  class="form-control"
                                  required
                                >
                                  <option value="False"></option>
                                  <option value="True">Yes</option>
                                  <option value="False">No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="card-footer text-center">
                            <button
                              class="btn btn-success"
                              v-on:click="processkpiperformancedetailspromo()"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-pane>
                  <tab-pane
                    icon="ni ni-tag"
                    title="Share of Shelf"
                    v-if="resultoutlet.ChannelId == 3"
                  >
                    <div class="description">
                      <div class="card bg-secondary">
                        <div class="card-header">
                          <h3 class="card-title">Share of Shelf</h3>
                        </div>
                        <div class="card-body">
                          <div class="form-group">
                            <label>Brand</label>
                            <select
                              v-model="ShareofShelfBrandIdval"
                              class="form-control"
                              required
                            >
                              <option value="0"></option>
                              <option
                                v-for="item in resultShareofShelfItems"
                                :key="item.Id"
                                v-bind:value="item.Id"
                              >
                                {{ item.BrandName }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group">
                            <span>Total Category Facing</span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="TotalCategoryFacingval"
                            />
                          </div>
                          <div class="form-group">
                            <span>Total Unilever Facing</span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="TotalUnileverFacingval"
                            />
                          </div>
                          <div class="card-footer text-center">
                            <button
                              class="btn btn-success"
                              v-on:click="processkpiperformanceshareofshelf()"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-pane>
                  <tab-pane icon="ni ni-tag" title="Shelf Standard">
                    <div class="description">
                      <div class="card bg-secondary">
                        <div class="card-header">
                          <h3 class="card-title">Shelf Standard</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Brand</label>
                                <select
                                  v-model="BrandShelfStandardIdval"
                                  class="form-control"
                                  required
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in resultBrandShelfStandardItems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.BrandName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <span
                                  >Is Product being displayed in dedicated brand
                                  image window</span
                                >
                                <select
                                  v-model="IsBrandDisplayedval"
                                  class="form-control"
                                  required
                                >
                                  <option value="False"></option>
                                  <option value="True">Yes</option>
                                  <option value="False">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <span class="mr-4"
                                  >Displayed as per planogram</span
                                >
                                <select
                                  v-model="IsDisplayedAsPlanogramval"
                                  class="form-control"
                                  required
                                >
                                  <option value="False"></option>
                                  <option value="True">Yes</option>
                                  <option value="False">No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="card-footer text-center">
                            <button
                              class="btn btn-success"
                              v-on:click="
                                processkpiperformanceproductshelfstandard()
                              "
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-pane>
                  <tab-pane icon="ni ni-tag" title="Call to Action">
                    <div class="description">
                      <div class="card bg-secondary">
                        <div class="card-header">
                          <h3 class="card-title">Call to Action</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Brand</label>
                                <select
                                  v-model="BrandCalltoActionIdval"
                                  class="form-control"
                                  required
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in resultBrandCalltoActionItems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.BrandName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <span>Is Call to Action Communicated</span>
                                <select
                                  v-model="IsCallToActionCommunicatedval"
                                  class="form-control"
                                  required
                                >
                                  <option value="False"></option>
                                  <option value="True">Yes</option>
                                  <option value="False">No</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <span class="mr-4"
                                  >Is the right call to action communicated for
                                  the brand</span
                                >
                                <select
                                  v-model="
                                    IsRightCallToActionCommunicatedforBrandval
                                  "
                                  class="form-control"
                                  required
                                >
                                  <option value="False"></option>
                                  <option value="True">Yes</option>
                                  <option value="False">No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="card-footer text-center">
                            <button
                              class="btn btn-success"
                              v-on:click="processkpiperformancecalltoaction()"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-pane>
                </card>
              </tabs>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import moment from "moment";
export default {
  name: "KpiPerformance",
  data() {
    return {
      KpiPerformanceIdval: localStorage.getItem("KpiPerformanceId") || "",
      OsaProductIdval: 0,
      OsaProductUnitval: "",
      OsaProductPriceDisplayval: "",
      OsaShareofShelfval: "",
      NpdProductIdval: 0,
      NpdProductUnitval: "",
      NpdProductPriceDisplayval: "",
      NpdShareofShelfval: "",
      PromoProductIdval: 0,
      CompetitorProductPriceval: "",
      ShareofShelfBrandIdval: 0,
      IsPromoRuningval: false,
      IsRetailerAwareval: false,
      Commentval: "",
      ProductCompetitorval: 0,
      TotalCategoryFacingval: "",
      TotalUnileverFacingval: "",
      BrandShelfStandardIdval: 0,
      IsBrandDisplayedval: false,
      IsDisplayedAsPlanogramval: false,
      BrandCalltoActionIdval: 0,
      IsCallToActionCommunicatedval: false,
      IsRightCallToActionCommunicatedforBrandval: false,
      resultKpiPerformItems: null,
      resultOsaItems: null,
      resultPromoItems: null,
      resultNpdItems: null,
      resultShareofShelfItems: null,
      resultProductCompetitorItems: null,
      resultTempProductCompetitorItems: null,
      resultBrandShelfStandardItems: null,
      resultBrandCalltoActionItems: null,
      resultKpiReference: null,
      resultoutlet: null,
      ImagePathval: null,
      file: "",
      uploadedimage: "",
      onloader: "none",
    };
  },
  methods: {
    processkpiperformancedetailsosa: function() {
      let KpiPerformanceId = this.KpiPerformanceIdval;
      let ProductId = this.OsaProductIdval;
      let ProductUnit = this.OsaProductUnitval;
      let ProductPriceDisplay = this.OsaProductPriceDisplayval;
      let ShareofShelf = this.OsaShareofShelfval;
      let IsPromo = false;
      let IsNpd = false;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        KpiPerformanceId,
        ProductId,
        ProductUnit,
        ProductPriceDisplay,
        ShareofShelf,
        IsPromo,
        IsNpd,
        CreatedByUsername,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processkpiperformancedetails", userdata)
        .then(() => {
          this.getproductosaformerchandiser();
          this.getkpiPerformanceproductcompetitivetemp();
          this.OsaProductUnitval = "";
          this.OsaProductIdval = 0;
          this.OsaProductPriceDisplayval = "";
          this.OsaShareofShelfval = "";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getproductosaformerchandiser: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getproductosaformerchandiser", this.KpiPerformanceIdval)
        .then((resp) => {
          this.resultOsaItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    processkpiperformancedetailsnpd: function() {
      let KpiPerformanceId = this.KpiPerformanceIdval;
      let ProductId = this.NpdProductIdval;
      let ProductUnit = this.NpdProductUnitval;
      let ProductPriceDisplay = this.NpdProductPriceDisplayval;
      let ShareofShelf = this.NpdShareofShelfval;
      let IsPromo = false;
      let IsNpd = true;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        KpiPerformanceId,
        ProductId,
        ProductUnit,
        ProductPriceDisplay,
        ShareofShelf,
        IsPromo,
        IsNpd,
        CreatedByUsername,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processkpiperformancedetails", userdata)
        .then(() => {
          this.getproductnpdformerchandiser();
          this.NpdProductUnitval = "";
          this.NpdProductIdval = 0;
          this.NpdProductPriceDisplayval = "";
          this.NpdShareofShelfval = "";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getproductnpdformerchandiser: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getproductnpdformerchandiser", this.KpiPerformanceIdval)
        .then((resp) => {
          this.resultNpdItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    processkpiperformancedetailspromo: function() {
      let KpiPerformanceId = this.KpiPerformanceIdval;
      let ProductId = this.PromoProductIdval;
      let IsPromoRuning = this.IsPromoRuningval;
      let IsRetailerAware = this.IsRetailerAwareval;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        KpiPerformanceId,
        ProductId,
        IsPromoRuning,
        IsRetailerAware,
        CreatedByUsername,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processkpiperformanceproductpromo", userdata)
        .then(() => {
          this.getproductpromoformerchandiser();
          this.PromoProductUnitval = "";
          this.PromoProductIdval = 0;
          this.IsPromoRuningval = false;
          this.IsRetailerAwareval = false;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getbrandshareofshelfformerchandiser: function() {
      this.onloader = "flex";
      this.$store
        .dispatch(
          "getbrandshareofshelfformerchandiser",
          this.KpiPerformanceIdval
        )
        .then((resp) => {
          this.resultShareofShelfItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getbrandsharestandardformachandiser: function() {
      this.onloader = "flex";
      this.$store
        .dispatch(
          "getbrandsharestandardformachandiser",
          this.KpiPerformanceIdval
        )
        .then((resp) => {
          this.resultBrandShelfStandardItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    processkpiperformanceshareofshelf: function() {
      let KpiPerformanceId = this.KpiPerformanceIdval;
      let BrandId = this.ShareofShelfBrandIdval;
      let TotalCategoryFacing = this.TotalCategoryFacingval;
      let TotalUnileverFacing = this.TotalUnileverFacingval;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        KpiPerformanceId,
        BrandId,
        TotalCategoryFacing,
        TotalUnileverFacing,
        CreatedByUsername,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processkpiperformanceshareofshelf", userdata)
        .then(() => {
          this.getbrandshareofshelfformerchandiser();
          this.ShareofShelfBrandIdval = 0;
          this.TotalCategoryFacingval = "";
          this.TotalUnileverFacingval = "";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },    
    processkpiperformanceproductshelfstandard: function() {
      let Id = 1;
      let KpiPerformanceId = this.KpiPerformanceIdval;
      let BrandId = this.BrandShelfStandardIdval;
      let IsBrandDisplayed = this.IsBrandDisplayedval;
      let IsDisplayedAsPlanogram = this.IsDisplayedAsPlanogramval;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        Id,
        KpiPerformanceId,
        BrandId,
        IsBrandDisplayed,
        IsDisplayedAsPlanogram,
        CreatedByUsername,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processkpiperformanceproductshelfstandard", userdata)
        .then(() => {
          this.getbrandsharestandardformachandiser();
          this.BrandShelfStandardIdval = 0;
          this.IsBrandDisplayedval = false;
          this.IsDisplayedAsPlanogramval = false;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getbrandcalltoactionformachandiser: function() {
      this.onloader = "flex";
      this.$store
        .dispatch(
          "getbrandcalltoactionformachandiser",
          this.KpiPerformanceIdval
        )
        .then((resp) => {
          this.resultBrandCalltoActionItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    processkpiperformancecalltoaction: function() {
      let Id = 1;
      let KpiPerformanceId = this.KpiPerformanceIdval;
      let BrandId = this.BrandCalltoActionIdval;
      let IsCallToActionCommunicated = this.IsCallToActionCommunicatedval;
      let IsRightCallToActionCommunicatedforBrand = this
        .IsRightCallToActionCommunicatedforBrandval;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        Id,
        KpiPerformanceId,
        BrandId,
        IsCallToActionCommunicated,
        IsRightCallToActionCommunicatedforBrand,
        CreatedByUsername,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processkpiperformancecalltoaction", userdata)
        .then(() => {
          this.getbrandcalltoactionformachandiser();
          this.BrandCalltoActionIdval = 0;
          this.IsCallToActionCommunicatedval = false;
          this.IsRightCallToActionCommunicatedforBrandval = false;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    processkpiPerformanceproductcompetitivetemp: function() {
      let Id = 1;
      let KpiPerformanceId = this.KpiPerformanceIdval;
      let ProductCompetitorId = this.ProductCompetitorval;
      let ProductPrice = this.CompetitorProductPriceval;
      let ProductId = this.OsaProductIdval;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        Id,
        ProductId,
        KpiPerformanceId,
        ProductCompetitorId,
        ProductPrice,
        CreatedByUsername,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processkpiPerformanceproductcompetitivetemp", userdata)
        .then((resp) => {
          this.resultTempProductCompetitorItems = resp;
          this.CompetitorProductPriceval = "";
          this.ProductCompetitorval = 0;         
          this.onloader = "none";
          this.getproductcompetitor();
          swal({
            title: "Success!",
            text: "Added successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getkpiPerformanceproductcompetitivetemp: function() {
      this.onloader = "flex";
      let KpiPerformanceId = this.KpiPerformanceIdval;
      let ProductId = this.OsaProductIdval;
      let userdata = { KpiPerformanceId, ProductId };
      this.$store
        .dispatch("getkpiPerformanceproductcompetitivetemp", userdata)
        .then((resp) => {
          this.resultTempProductCompetitorItems = resp;
          this.CompetitorProductPriceval = "";
          this.ProductCompetitorval = 0;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getproductpromoformerchandiser: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getproductpromoformerchandiser", this.KpiPerformanceIdval)
        .then((resp) => {
          this.resultPromoItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getkpiperformance: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpiperformancebyid", this.KpiPerformanceIdval)
        .then((resp) => {
          this.resultKpiPerformItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getproductcompetitor: function() {
      this.onloader = "flex";
      let ProductId=this.OsaProductIdval;
      let KpiPerformanceId=this.KpiPerformanceIdval;
      let userdata={ProductId,KpiPerformanceId}
      this.$store
        .dispatch("getproductcompetitorforkpiperfomance", userdata)
        .then((resp) => {
          this.resultProductCompetitorItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
    },
    submitFile: function() {
      this.onloader = "flex";
      var form = new FormData();
      let formalurl = this.ImagePathval;
      let doctype = "image";
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadfile", form)
        .then((resp) => {
          this.onloader = "none";
          this.ImagePathval = resp;
          this.uploadedimage = resp;
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getkpireferencecurrent: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpireferencecurrent")
        .then((resp) => {
          this.resultKpiReference = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    getoutletbykpiperformance: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getoutletbykpiperformance", this.KpiPerformanceIdval)
        .then((resp) => {
          this.resultoutlet = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (
      localStorage.getItem("KpiPerformanceId") == null ||
      localStorage.getItem("KpiPerformanceId") == undefined
    ) {
      this.$router.push("/dashboard");
    } else {
      this.getkpiperformance();
      this.getproductosaformerchandiser();
      this.getproductnpdformerchandiser();
      this.getproductpromoformerchandiser();
      this.getbrandshareofshelfformerchandiser();
      this.getbrandsharestandardformachandiser();
      this.getbrandcalltoactionformachandiser();
      this.getkpireferencecurrent();
      this.getoutletbykpiperformance();
    }
  },
};
</script>

<style></style>
