<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <span class="text-white"><i class="ni ni-bold-right"></i> Logs </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Exception Description</th>
                    <th>Exception</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resultItems" :key="item.Id">
                    <td>{{ item.DateCreated | formatDateTime }}</td>
                    <td>{{ item.ExceptionDescription }}</td>
                    <td>{{ item.ExceptionDetails }}</td>
                    <td>
                      <button
                        class="btn btn-secondary btn-sm my-2"
                        v-on:click="deletelog(item.Id)"
                      >
                        <i class="fa fa-times"></i> Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "sKU",
  data() {
    return {
      Idval: 1,
      resultItems: null,
      onloader: "none",
    };
  },
  methods: {
    deletelog: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("deletelog", Id)
        .then((resp) => {
          this.resultItems = resp;         
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },    
    getlogs: function() {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getlogs")
        .then((resp) => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },    
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getlogs();    
  },
};
</script>

<style></style>
