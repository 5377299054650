<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i> KPI </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
        <card header-classes="bg-transparent">
            <div class="row">
                 <div class="col-md-4">
                <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body">
                        <div class="form-horizontal">
                            <form role="form" @submit.prevent="submitdata">                                                             
                                 <div class="form-group">
                                    <label>KPI Name *</label>
                                    <input v-model="KpiNameval" class="form-control" required>
                                </div>                                
                                 <div class="form-group">
                                    <label>Short Name *</label>
                                    <input v-model="ShortNameval" class="form-control" required>
                                </div>                                                    
                                <hr>
                                <div class="text-center mb-3">                                    
                                    <button type="submit" class="btn btn-primary my-4">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="form-inline">                    
                    <div class=" input-group">
                        <input v-model="searchval" class="form-control" @keyup="getsearch" placeholder="Search...">
                        <div class=" input-group-append">
                            <button type="button" @click.prevent="getsearch" class="btn btn-secondary"><i class="fa fa-search"></i> Search </button>
                        </div>
                    </div>
                </div>
                <div class=" table-responsive m-3" v-if="resultitems">  
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>KPI</th>
                                <th>Short Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in resultitems" :key="item.Id">
                                <td>{{item.KpiName}}</td>
                                <td>{{item.ShortName}}</td>
                                <td> <button class="btn btn-secondary btn-sm my-2" v-on:click="edititem(item.Id)"><i class="fa fa-edit"></i> Edit</button></td>
                            </tr>
                        </tbody>
                        </table>                    
                </div>
            </div>
            </div>           
        </card>
    </div>
</div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "outlet",
  data() {
    return {
      Idval: 1,
      KpiNameval: "",
      ShortNameval: "",
      resultitems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  methods: {
    newterm: function () {
      this.KpiNameval = "";
      this.ShortNameval = "";
      this.searchval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function () {
      this.KpiNameval = "";
      this.ShortNameval = "";
      this.searchval = "";
      this.openform = false;
      this.process = "N";
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpibyid", Id)
        .then((resp) => {         
          this.Idval = resp.Id;
          this.KpiNameval = resp.KpiName;
          this.ShortNameval = resp.ShortName;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    submitdata: function () {
      let Id = this.Idval;
      let KpiName = this.KpiNameval;
      let ShortName = this.ShortNameval;
      let Operation = this.process;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        Id,
        KpiName,
        ShortName,
        Operation,
        CreatedByUsername,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("processkpi", userdata)
        .then((resp) => {
          this.resultitems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getkpi: function () {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getkpi")
        .then((resp) => {
          this.resultitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function () {
      this.$store
        .dispatch("searchkpi", this.searchval)
        .then((resp) => {
          this.resultitems = resp;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },

    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getkpi();
  },
};
</script>

<style></style>
