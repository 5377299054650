<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <div class="row" v-if="ReportItems">     
     
      <div class="col">
        <div class="card bgGreen shrink my-3">
          <div class="card-body">
            <h2 class="text-white"> {{ ReportItems.TotalMerchandiser }}</h2>   
             <h5 class="text-white">
              Total number of merchandisers
            </h5>
            
          </div>
          <div class="card-footer bgGreenActive text-white"></div>
        </div>
      </div>
      <div class="col">
        <div class="card bgYellow my-3 shrink">
          <div class="card-body">
            <h2 class="text-white"> {{ ReportItems.TotalStore }}</h2>   
             <h5 class="text-white">
              Total number of stores
            </h5>
            
          </div>
          <div class="card-footer bgYellowActive text-white"></div>
        </div>
      </div>   
      <div class="col">
        <div class="card my-3 bgRed shrink">
          <div class="card-body">
            <h2 class="text-white"> {{ ReportItems.TotalSku }}</h2>   
             <h5 class="text-white">
              Total number of sku
            </h5>
            
          </div>
          <div class="card-footer bgRedActive text-white"></div>
        </div>
      </div>  
      <div class="col">
        <div class="card my-3 bgGreen shrink">
          <div class="card-body">
            <h2 class="text-white"> {{ ReportItems.TotalSupervisor }}</h2>   
             <h5 class="text-white">
              Total number of supervisor
            </h5>
            
          </div>
          <div class="card-footer bgGreenActive text-white"></div>
        </div>
      </div>      
      <div class="col">
        <div class="card my-3 bgYellow shrink">
          <div class="card-body">
            <h2 class="text-white"> {{ ReportItems.TotalStoreMerchandized }}</h2>   
             <h5 class="text-white">
              Total number of merchandised
            </h5>
            
          </div>
          <div class="card-footer bgYellowActive text-white"></div>
        </div>
      </div>  
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
export default {
  name: "permitoutvisit",
  data() {
    return {
      ReportItems: null,
      onloader: "none"
    };
  },

  methods: {
    GetAppSummary() {
      this.onloader = "flex";
      this.$store
        .dispatch("GetAppSummary")
        .then(resp => {
          this.ReportItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.GetAppSummary();
  }
};
</script>
<style>
.bgGreen{
  background: #228B22 !important;
}
.bgGreenActive{
  background: #006400 !important;
}
.bgYellow{
  background: #FFC000 !important;
}
.bgYellowActive{
  background: #DAA520 !important;
}
.bgRed{
  background: #ff0000 !important;
}
.bgRedActive{
  background: #cc0000 !important;
}
</style>