<template>
<div class="row">

    <!-- Header -->
    <div class="col-lg-6 justify-content-center pb-3 text-center">
        <div class="py-5">
            <img v-if="userinfo.Passport" alt="" :src="$store.state.auth.baseurl + userinfo.Passport.substr(1)" height="100" />
            <img v-else src="img/brand/loguser.png" alt="" height="100" />
            <h3 class="text-white">{{userinfo.FullName }}</h3>
        </div>

    </div>
    <div class="col-lg-6">
        <div v-if="userinfo.OrgId" class="mx-5 shrink">
            <router-link to="/dashboard">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-md-3 bg-translucent-secondary text-dark justify-content-center text-center pt-5">
                            <i class="ni ni-building ni-2x"></i>
                        </div>
                        <div class="col-md-9 pt-1 bg-translucent-dark">

                            <h3 class="pt-3 text-white">School Portal</h3>
                            <div class="progress">
                                <div class="progress-bar bg-default" style="width: 70%"></div>
                            </div>
                            <p><small>Secured Portal for Students, Parents, Teachers, Accountants and Administrator</small> </p>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="mx-5 shrink">
            <router-link to="/home">
                <div class=" mb-3">
                    <div class="row">
                        <div class="col-md-3 bg-translucent-secondary text-dark justify-content-center text-center pt-5">
                            <i class="ni ni-laptop ni-2x"></i>
                        </div>
                        <div class="col-md-9 pt-1 bg-translucent-dark">

                            <h3 class="pt-3 text-white">CBT</h3>
                            <div class="progress">
                                <div class="progress-bar bg-default" style="width: 70%"></div>
                            </div>
                            <p><small>Take school exam and Practice Exam Like Common Entrance, WAEC, NECO, GCE, NABTEB, JAMB.</small></p>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="mx-5 shrink">
            <router-link to="/home">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-md-3 bg-translucent-secondary text-dark justify-content-center text-center pt-5">
                            <i class="ni ni-books ni-2x"></i>
                        </div>
                        <div class="col-md-9 pt-1 bg-translucent-dark">

                            <h3 class="pt-3 text-white">i-Learning</h3>
                            <div class="progress">
                                <div class="progress-bar bg-default" style="width: 70%"></div>
                            </div>
                            <p><small>Flexible learning experience, with several learning and teaching technologies</small> </p>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'login',
    data() {
        return {
            userinfo: {},
        }
    },
    methods: {
        getuser: function () {
            this.onloader = 'flex';
            this.$store.dispatch('getuser')
                .then((resp) => {
                    this.userinfo = resp;
                    this.onloader = 'none';
                    if (resp.Status_Id == 6 || resp.Status_Id == 7 || resp.Status_Id == 9) {
                        this.$store.dispatch('logout')
                        this.$router.push('/login')
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none';
                    this.$store.dispatch('logout');
                    this.$router.push('/login');
                })
        },
    },
    mounted() {
        this.getuser();
    }
}
</script>

<style>
</style>
