<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <span class="text-white"><i class="ni ni-bold-right"></i> Users </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div v-if="openform">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitdata">
                  <div>
                    <img
                      v-if="uploadedimage"
                      height="100"
                      :src="uploadedimage"
                    />
                    <img v-else height="100" src="img/brand/empty.png" /><br />
                    <input
                      type="file"
                      id="file"
                      ref="file"
                      accept=".png,.jpg,.jpeg"
                      v-on:change="handleFileUpload()"
                    />
                    <br />
                    <button
                      type="button"
                      @click.prevent="submitFile"
                      class="btn btn-secondary"
                    >
                      <i class="fa fa-upload"></i>
                      Upload
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Full Name *</label>
                        <input
                          v-model="FullNameval"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Email Address *</label>
                        <input
                          v-model="EmailAddressval"
                          type="Email"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Phone Number *</label>
                        <input
                          v-model="PhoneNumberval"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Role</label>
                        <select v-model="RoleIdval" class="form-control">
                          <option value="0"></option>
                          <option
                            v-for="item in roleData"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            {{ item.RoleName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4" v-if="RoleIdval == 3">
                      <div class="form-group">
                        <label>Supervisor</label>
                        <select
                          v-model="SupervisorIdval"
                          class="form-control"
                          required
                        >
                          <option value="0"></option>
                          <option
                            v-for="item in resultSupervisorItems"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            {{ item.FullName }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="text-center mb-3">
                    <button
                      type="button"
                      @click.stop="closeform()"
                      class="btn btn-secondary"
                    >
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary my-4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-inline">           
           <div class="justify-content-end form-inline">
            <button
              type="button"
              @click.prevent="newterm"
              class="btn btn-default"
            >
              <i class="ni ni-fat-add"></i>Add New
            </button>
            <base-input
              class="input-group-alternative"
              placeholder="Search here..."
              addon-left-icon="fa fa-search"
              v-model="searchval"
            >
            </base-input>
          </div>
          </div>
          <div class="row" v-if="resultitems">            
            <div class="col-lg-4" v-for="item in searchedResultQuery" :key="item.Id">
              <div class="card bg-success shrink my-3">
                <div class="card-body text-center">
                  <img
                    v-if="item.ImagePath"
                    :src="item.ImagePath"
                    class="img-fluid"
                    alt=""
                  />
                  <img
                    v-else
                    src="img/brand/empty.png"
                    class="img-fluid"
                    alt=""
                  />
                  <h5 class="text-white mt-2">{{ item.FullName }}</h5>
                  <h6 class="text-white">{{ item.RoleName }}</h6>
                  <h6 class="text-white">{{ item.PhoneNumber }}</h6>
                  <h6 class="text-white">{{ item.EmailAddress }}</h6>
                  <div class="card-footer">
                    <button
                      class="btn btn-secondary btn-sm my-2"
                      v-on:click="edititem(item.Id)"
                    >
                      <i class="fa fa-edit"></i> Edit
                    </button>
                    <button
                      class="btn btn-secondary btn-sm my-2"
                      v-on:click="openmerchantoutlet(item.Id)"
                    >
                      <i class="fa fa-anchor"></i> Assign Store
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "user",
  data() {
    return {
      Idval: 1,
      FullNameval: "",
      PhoneNumberval: "",
      EmailAddressval: "",
      SupervisorIdval: 0,
      RoleIdval: 0,
      ImagePathval: null,
      file: "",
      uploadedimage: "",
      resultSupervisorItems: null,
      resultitems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      roleData: (state) => state.app.roleData,
    }),
    searchedResultQuery() {
      if (this.searchval) {
        return this.resultitems.filter((item) => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.FullName.toLowerCase().includes(v) ||
                item.RoleName.toLowerCase().includes(v) ||
                item.EmailAddress.toLowerCase().includes(v) ||
                item.PhoneNumber.toLowerCase().includes(v)
            );
        });
      } else {
        return this.resultitems;
      }
    },
  },
  methods: {
    newterm: function () {
      this.FullNameval = "";
      this.PhoneNumberval = "";
      this.EmailAddressval = "";
      this.RoleIdval = 0;
      this.ImagePathval = null;
      this.searchval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function () {
      this.FullNameval = "";
      this.PhoneNumberval = "";
      this.EmailAddressval = "";
      this.RoleIdval = 0;
      this.ImagePathval = null;
      this.searchval = "";
      this.openform = false;
      this.process = "N";
    },
    openmerchantoutlet: function (Id) {
      localStorage.setItem("UserId", Id);
      this.$router.push("/merchantoutlet");
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getuserbyid", Id)
        .then((resp) => {
          console.log(resp);
          this.Idval = resp.Id;
          this.RoleIdval = resp.RoleId;
          this.FullNameval = resp.FullName;
          this.EmailAddressval = resp.EmailAddress;
          this.PhoneNumberval = resp.PhoneNumber;
          this.SupervisorIdval = resp.SupervisorId;
          if (resp.ImagePath != null) {
            this.uploadedimage = resp.ImagePath;
          }
          this.ImagePathval = resp.ImagePath;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    submitdata: function () {
      let Id = this.Idval;
      let FullName = this.FullNameval;
      let EmailAddress = this.EmailAddressval;
      let PhoneNumber = this.PhoneNumberval;
      let RoleId = this.RoleIdval;
      let SupervisorId = this.SupervisorIdval;
      let ImagePath = this.ImagePathval;
      let Operation = this.process;
      let CreatedByUsername = this.$store.state.auth.token;
      if (RoleId > 0) {
        let userdata = {
          Id,
          FullName,
          EmailAddress,
          PhoneNumber,
          RoleId,
          SupervisorId,
          ImagePath,
          Operation,
          CreatedByUsername,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("processuser", userdata)
          .then((resp) => {
            this.resultitems = resp;
            this.closeform();
            this.onloader = "none";
            swal({
              title: "Success!",
              text: "Submitted successfully",
              icon: "success",
              button: "Ok",
            });
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getusers: function () {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getusers")
        .then((resp) => {
          this.resultitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsupervisor: function () {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getsupervisor")
        .then((resp) => {
          this.resultSupervisorItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function () {
      this.$store
        .dispatch("searchuser", this.searchval)
        .then((resp) => {
          this.resultitems = resp;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
    },
    submitFile: function () {
      this.onloader = "flex";
      var form = new FormData();
      let formalurl = this.ImagePathval;
      let doctype = "image";
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadfile", form)
        .then((resp) => {
          this.onloader = "none";
          this.ImagePathval = resp;
          this.uploadedimage = resp;
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getusers();
    this.getsupervisor();
  },
};
</script>

<style></style>
