<template>
  <div class="flex-column-reverse">
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary shadow border-0">
            <div class="card-body">
              <div class="text-center text-muted my-3">
                <router-link to="/login" class="nav-link">
                  <img src="img/brand/green.png" height="50"
                /></router-link>
                <h3>PERFECT STORE MERCHANDISING</h3>
                <h5 class="mt-3">
                  Submit details below to change your password
                </h5>
              </div>
              <form role="form" @submit.prevent="userdata">
                <base-input
                  class="input-group-alternative"
                  placeholder="New Password"
                  type="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="newpassword"
                >
                </base-input>

                <div class="text-center">
                  <button type="submit" class="my-4 btn btn-primary btn-block">
                    Submit
                  </button>
                </div>
              </form>
              <div class="text-center pt-2">
                <router-link to="/login" class="text-primary"
                  ><small>Back to Login</small></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";

export default {
  name: "changepassword",
  data() {
    return {
      username: "",
      newpassword: "",
      errormsg: "",
      onloader: "none",
    };
  },
  methods: {
    userdata: function() {
      let Username = this.$store.state.auth.username;
      let NewPassword = this.newpassword;
      console.log(Username)
      this.onloader = "flex";
      this.$store
        .dispatch("changepassword", {
          NewPassword,
          Username,
        })
        .then(() => {
          this.onloader = "none";
          this.$router.push("/login");
          swal({
            title: "Success!",
            text:
              "Your password has been change successfuly, you can now login with new password",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          swal({
            title: "Oops!",
            text: err.response.data,
            icon: "error",
            button: "Ok",
          });
        });
    },
  },
};
</script>

<style></style>
