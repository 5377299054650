<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="pb-6">
        <div class="row">
          <div class="col-md-8">
            <h4 class="text-white">
              Welcome {{ $store.state.auth.user_fullName }}!
            </h4>
            <small class="text-white"
              ><i class="fa fa-phone"></i> {{ $store.state.auth.user_phone }} ||
              <i class="fa fa-envelope"></i>
              {{ $store.state.auth.user_email }}</small
            >        
            <span class=" text-break"></span>
            <h4 class="text-white py-3" v-if="resultKpiReference"> <b>{{resultKpiReference.KpiDescription}}</b> </h4>
          </div>
        </div>
      </div>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-header">
                <h3 class="card-title">
                  <span class="text-left">
                    <img
                      src="img/brand/map.png"
                      class="card-header-icon"
                      alt=""
                      height="20px"
                    />
                  </span>
                  Today's Visits
                </h3>
              </div>
              <div class="card-body">
                <div v-for="item in resultAssignedItems" :key="item.Id">
                  <div class="card bg-secondary">
                    <div class="card-body text-left">
                      <div class="row">
                        <div class="col-md-3">
                          <img
                            v-if="item.OutletImage"
                            :src="
                              $store.state.auth.baseurl +
                                item.OutletImage.substr(1)
                            "
                            class="img-fluid"
                            alt=""
                          />
                          <img
                            v-else
                            src="img/brand/empty.png"
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <div class="col-md-7">
                          <h1>{{ item.OutletName }}</h1>
                          <h3>{{ item.OutletCode }}</h3>
                          <h5>{{ item.RegionName }}</h5>
                          <h6>{{ item.Location }}</h6>
                        </div>
                        <div class="col-md-2 text-center">
                          <button
                            class="btn btn-success btn-lg my-5"
                            v-on:click="visit(item.OutletId)"
                          >
                            <i class="fa fa-map-marker"></i> Visit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">
                  Notification
                </h6>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>
<script
  src="http://maps.google.com/maps/api/js?sensor=false&libraries=geometry"
  type="text/javascript"
></script>
<script>
import swal from "sweetalert";
export default {
  data() {
    return {
      KpiReferenceIdval: 0,
      resultNotAssignedItems: null,
      resultAssignedItems: null,
      resultKpiReference:null,
      center: { lat: 45.508, lng: -73.587 },
      currentPlace: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  methods: {
    getuser: function() {
      this.$store
        .dispatch("getuser")
        .then(() => {})
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    geoDistance(lat1, lng1, lat2, lng2) {
      const a = 6378.137; // equitorial radius in km
      const b = 6356.752; // polar radius in km

      var sq = (x) => x * x;
      var sqr = (x) => Math.sqrt(x);
      var cos = (x) => Math.cos(x);
      var sin = (x) => Math.sin(x);
      var radius = (lat) =>
        sqr(
          (sq(a * a * cos(lat)) + sq(b * b * sin(lat))) /
            (sq(a * cos(lat)) + sq(b * sin(lat)))
        );

      lat1 = (lat1 * Math.PI) / 180;
      lng1 = (lng1 * Math.PI) / 180;
      lat2 = (lat2 * Math.PI) / 180;
      lng2 = (lng2 * Math.PI) / 180;

      var R1 = radius(lat1);
      var x1 = R1 * cos(lat1) * cos(lng1);
      var y1 = R1 * cos(lat1) * sin(lng1);
      var z1 = R1 * sin(lat1);

      var R2 = radius(lat2);
      var x2 = R2 * cos(lat2) * cos(lng2);
      var y2 = R2 * cos(lat2) * sin(lng2);
      var z2 = R2 * sin(lat2);

      return sqr(sq(x1 - x2) + sq(y1 - y2) + sq(z1 - z2));
    },
    visit: function(OutletId) {
      this.onloader = "flex";
      this.$store
        .dispatch("getoutletbyid", OutletId)
        .then((resp) => {          
          // var distance = google.maps.geometry.spherical.computeDistanceBetween(
          //  new google.maps.LatLng(this.center.lat, this.center.lng),
          // new google.maps.LatLng(resp.Latitude, resp.Longitude)
          // );
          var distance = this.geoDistance(
            this.center.lat,
            this.center.lng,
            resp.Latitude,
            resp.Longitude
          );
          distance = distance * 1000;         
          if (distance >= -100 && distance <= 100) {           
            this.loginkpiperformance(OutletId);
          } else {
             this.loginkpiperformance(OutletId);
            // swal({
            //   title: "Oops!",
            //   text: "You are not at store location, go there and try again",
            //   icon: "error",
            //   button: "Ok",
            // });
          }
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    loginkpiperformance: function(OutletId) {       
      let UserId = this.$store.state.auth.user_id;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = { OutletId, UserId, CreatedByUsername };
      this.onloader = "flex";
      this.$store
        .dispatch("loginkpiperformance", userdata)
        .then((resp) => {         
          localStorage.setItem("KpiPerformanceId", resp.Id);
          this.$router.push("/kpiperformance");
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoutletassigned: function() {
      this.onloader = "flex";     
      this.$store
        .dispatch("getmerchandiseroutletforkpiperformance", this.KpiReferenceIdval)
        .then((resp) => {
          this.resultAssignedItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    getkpireferencecurrent: function() {
      console.log
      this.onloader = "flex";
      this.$store
        .dispatch("getkpireferencecurrent")
        .then((resp) => {
          this.KpiReferenceIdval=resp.Id;
          this.resultKpiReference=resp;
          this.getoutletassigned();
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getuser();
    this.getkpireferencecurrent(); 
    this.geolocate();
    
  },
};
</script>
<style></style>
