<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <span class="text-white"><i class="ni ni-bold-right"></i> Brand </span>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-group">
                      <label>Brand Name *</label>
                      <input
                        v-model="ProductBrandNameval"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Category</label>
                      <select
                        v-model="ProductCategoryIdval"
                        class="form-control"
                        required
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in productCategoryItems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.ProductCategoryName }}
                        </option>
                      </select>
                    </div>
                    <hr />
                    <div class="text-center mb-3">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-inline">
              <div class=" input-group">
                <input
                  v-model="searchval"
                  class="form-control"
                  @keyup="getsearch"
                  placeholder="Search..."
                />
                <div class=" input-group-append">
                  <button
                    type="button"
                    @click.prevent="getsearch"
                    class="btn btn-secondary"
                  >
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>
            <div class=" table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Brand</th>
                     <th>Category</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resultItems" :key="item.Id">
                    <td>{{ item.BrandName }}</td>
                     <td>{{ item.ProductCategoryName }}</td>
                    <td>
                      <button
                        class="btn btn-secondary btn-sm my-2"
                        v-on:click="edititem(item.Id)"
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "school",
  data() {
    return {
      Idval: 1,
      ProductBrandNameval: "",
      ProductCategoryIdval: 0,
      brandItems: null,
      productCategoryItems: null,
      resultItems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  methods: {
    newterm() {
      this.ProductBrandNameval = "";
      this.ProductCategoryIdval = 0;
      this.searchval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform() {
      this.ProductBrandNameval = "";
      this.ProductCategoryIdval = 0;
      this.searchval = "";
      this.openform = false;
      this.process = "N";
    },
    edititem(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getproductbrandbyid", Id)
        .then((resp) => {
          this.Idval = resp.Id;
          this.ProductBrandNameval = resp.BrandName;
          this.ProductCategoryIdval = resp.ProductCategoryId;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    submitdata() {
      let Id = this.Idval;
      let ProductBrandName = this.ProductBrandNameval;
      let ProductCategoryId = this.ProductCategoryIdval;
      let Operation = this.process;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        Id,
        ProductBrandName,
        ProductCategoryId,
        Operation,
        CreatedByUsername,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("processproductbrand", userdata)
        .then((resp) => {
          this.resultItems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getproductbrand() {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getproductbrand")
        .then((resp) => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch() {
      this.$store
        .dispatch("searchproductbrand", this.searchval)
        .then((resp) => {
          this.resultItems = resp;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    getproductcategory() {
      this.onloader = "flex";
      this.$store
        .dispatch("getproductcategory")
        .then((resp) => {
          this.productCategoryItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getproductbrand();
    this.getproductcategory();
  },
};
</script>

<style></style>
