<template>
    <div>      
      <Adminportal v-if="$store.state.auth.user_role_id==1"></Adminportal>
      <Merchandiserportal v-if="$store.state.auth.user_role_id==3"></Merchandiserportal>
    </div>
</template>
<script>

  import Adminportal from './Dashboardcomponent/Adminportal';
  import Merchandiserportal from './Dashboardcomponent/Merchandiserportal';
  export default {
    components: {    
     Adminportal,
     Merchandiserportal
    },  
    data() {
      return {       
       
      };
    },   
       
  };
</script>
<style></style>
