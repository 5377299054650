<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <router-link to="/sku" class="text-white">
        <i class="ni ni-bold-right"></i> Product</router-link
      >
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> SKU Competitor
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-5">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <h1 v-if="productItems" class="text-center">
                      {{ productItems.ProductName }} Product
                    </h1>
                    <div class="text-center my-3">
                      <input
                        v-model="CompetitorNameval"
                        class="form-control"
                        placeholder="Enter Competitor Name"
                        required
                      />
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7" v-if="resultItems">
            <div class="form-inline">
              <div class=" input-group">
                <input
                  v-model="searchval"
                  class="form-control"
                  @keyup="getsearch"
                  placeholder="Search..."
                />
                <div class=" input-group-append">
                  <button
                    type="button"
                    @click.prevent="getsearch"
                    class="btn btn-secondary"
                  >
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>
            <div class=" table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Product Competitor</th>
                    <th>Poduct</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resultItems" :key="item.Id">
                    <td>{{ item.CompetitorName }}</td>
                    <td>{{ item.ProductName }}</td>
                    <td>
                      <button
                        class="btn btn-secondary btn-sm my-2"
                        v-on:click="edititem(item.Id)"
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "productcompetitor",
  data() {
    return {
      Idval: 1,
      ProductIdval: localStorage.getItem("ProductId") || "",
      CompetitorNameval: "",
      productItems: null,
      resultItems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none"
    };
  },
  methods: {
    newterm: function() {
      this.CompetitorNameval = "";
      this.searchval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function() {
      this.CompetitorNameval = "";
      this.searchval = "";
      this.openform = false;
      this.process = "N";
    },
    edititem: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getproductcompetitorbyid", Id)
        .then(resp => {
          this.Idval = resp.Id;
          this.CompetitorNameval = resp.CompetitorName;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    submitdata: function() {
      let Id = this.Idval;
      let ProductId = this.ProductIdval;
      let CompetitorName = this.CompetitorNameval;
      let Operation = this.process;
      let CreatedByUsername = this.$store.state.auth.token;
      if (ProductId > 0) {
        let userdata = {
          Id,
          ProductId,
          CompetitorName,
          Operation,
          CreatedByUsername
        };

        this.onloader = "flex";
        this.$store
          .dispatch("processproductcompetitor", userdata)
          .then(resp => {
            this.resultItems = resp;
            this.closeform();
            this.onloader = "none";
            swal({
              title: "Success!",
              text: "Submitted successfully",
              icon: "success",
              button: "Ok"
            });
          })
          .catch(err => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getproductcompetitor: function() {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getproductcompetitor", this.ProductIdval)
        .then(resp => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function() {
      let ProductId = this.ProductIdval;
      let item = this.searchval;
      let userdata = { ProductId, item };
      this.$store
        .dispatch("searchproductcompetitor", userdata)
        .then(resp => {
          this.resultItems = resp;
        })
        .catch(err => {
          console.log(err.response.data);
        });
    },
    getsku: function() {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getproductbyid", this.ProductIdval)
        .then(resp => {
          this.productItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },

    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.getproductcompetitor();
    this.getsku();
  }
};
</script>

<style></style>
