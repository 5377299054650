<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/report" class="text-white">
        <i class="ni ni-badge"></i> Report
      </router-link>
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> General Pick Up Price Report</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-inline">
                <div class="text-center">
                  <div class="input-group">
                    <span class="input-group-text">KPI Index Details</span>
                    <select
                      v-model="KpiReferenceIdval"
                      class="form-control btn btn-success"
                      @change="downloadreport()"
                    >
                      <option value="0"></option>
                      <option
                        v-for="item in KpiReferenceItems"
                        :key="item.Id"
                        v-bind:value="item.Id"
                      >
                        {{ item.KpiDescription }}
                      </option>
                    </select>
                    <button v-if="KpiReferenceIdval>0"
                      class="btn btn-secondary"
                      @click.prevent="downloadreport()"
                    >
                      <i class="fa fa-print"></i> Spool
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div v-if="ReportItems">
            <div id="divReport">
              <div v-html="ReportItems"></div>
            </div>
            <div class="text-center">
              <button class="btn btn-default" @click.prevent="printreport()">
                <i class="fa fa-print"></i> Print
              </button>
              <button class="btn btn-default" @click.prevent="downloadreport()">
                <i class="fa fa-file-excel"></i> Export
              </button>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "generalreport",
  data() {
    return {
      KpiReferenceIdval: "",
      KpiReferenceItems: null,
      ReportItems: null,
      currdate: null,
      onloader: "none",
    };
  },

  methods: {
    getcurendate: function() {
      var today = new Date();
      this.currdate =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
    },
    printreport: function() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("divReport").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    getkpireference: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpireference")
        .then((resp) => {
          this.KpiReferenceItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    getreport: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getpricepickupgeneralreport", this.KpiReferenceIdval)
        .then((resp) => {
          this.ReportItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    downloadreport: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("downloadpricepickupgeneralreport", this.KpiReferenceIdval)
        .then((response) => {
          this.onloader = "none";
          console.log(response);
          if (response != null) {
            window.open(this.$store.state.auth.baseurl + response, "_blank");
          }
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getkpireference();
    this.getcurendate();
  },
};
</script>

<style>
table,
td,
th {
  border: 1px solid black;
}
table {
  width: 100%;
  font-size: 9pt;
  border-collapse: collapse;
}

.borderall {
  text-align: center;
  border: 3px solid #000;
}

.bordercol {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  border: thin solid #000;
}

.padbody {
  padding: 20px;
}

.padhead {
  padding: 5px;
  border: 3px solid #606060;
}

.pad {
  padding: 10px;
}

.bodytext {
  font-size: 9pt;
}
</style>
