<template>
    <div>
        <div id="loaderoverlay" v-bind:style="{ display: onloader }">
            <div class="w-100 d-flex justify-content-center align-items-center">
                <div class="loaderspinner"></div>
            </div>
        </div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                <i class="ni ni-tv-2"></i> Dashboard</router-link><i class="ni ni-bold-right text-white"></i>
            <router-link to="/planogram" class="text-white">
                <i class="ni ni-badge"></i> Planogram
            </router-link>
            <span class="text-white"><i class="ni ni-bold-right"></i> Product Planogram</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Product Category</label>
                                        <select v-model="ProductCategoryIdval" class="form-control"
                                            @change="GetProductByCateryId()" required>
                                            <option value="0"></option>
                                            <option v-for="item in productCategoryItems" :key="item.Id"
                                                v-bind:value="item.Id">
                                                {{ item.ProductCategoryName }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group" v-if="ProductCategoryIdval">
                                        <label>SKU *</label>
                                        <select v-model="ProductIdval" class="form-control" required>
                                            <option value="0"></option>
                                            <option v-for="item in productItems" :key="item.Id" v-bind:value="item.Id">
                                                {{ item.ProductName }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group" v-if="ProductIdval">
                                        <label>Order</label>
                                        <input type="number" min="1" v-model="ProductOrderval" class="form-control" />
                                    </div>
                                    <div class="card-footer text-center" v-if="ProductOrderval">
                                        <button class="btn btn-success" v-on:click="CreatePlanogramProductOrder()">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-8 table-responsive">
                                    <table class=" table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Category</th>
                                                <th>Order</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in resultItems" :key="item.Id">
                                                <td>{{ item.ProductName }}</td>
                                                <td>{{ item.ProductCategoryName }}</td>
                                                <td>{{ item.ProductOrder }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>

            <!-- End charts-->
        </div>
    </div>
</template>
  
<script>
import Alert from "@/mixins/Alert.mixins"
export default {
    name: "ProductPlanogram",
    mixins: [Alert],
    data() {
        return {
            ProductIdval: "",
            ProductCategoryIdval: "",
            productItems: null,
            KpiReferenceItems: null,
            ProductOrderval: '',
            productCategoryItems: null,
            resultItems: null,
            currdate: null,
            onloader: "none",
        };
    },

    methods: {

        CreatePlanogramProductOrder() {
            let ProductCategoryId = this.ProductCategoryIdval;
            let ProductId = this.ProductIdval;
            let ProductOrder = this.ProductOrderval;
            let CreatedByUsername = this.$store.state.auth.token;
            let userData = {
                ProductCategoryId,
                ProductId,
                ProductOrder,
                CreatedByUsername
            }
            this.onloader = "flex";
            this.$store
                .dispatch("CreatePlanogramProductOrder", userData)
                .then((resp) => {
                    this.onloader = "none";
                    if (resp.responseCode == '00') {
                        this.GetPlanogramProductOrder()
                        this.ShowAlertSuccess(resp.responseMessage, '')
                    } else {
                        this.ShowAlertError(resp.responseMessage, '')
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.onloader = "none";                   
                });
        },
        GetPlanogramProductOrder() {
            this.onloader = "flex";
            if (this.ProductCategoryIdval > 0) {
                this.$store
                    .dispatch("GetPlanogramProductOrder", this.ProductCategoryIdval)
                    .then((resp) => {
                        this.onloader = "none";
                        if (resp.responseCode == '00') {
                            this.resultItems = resp.data
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.onloader = "none";
                        // this.$store.dispatch("logout");
                        //this.$router.push("/login");
                    });
            }
        },
        getproductcategory() {
            this.onloader = "flex";
            this.$store
                .dispatch("getproductcategory")
                .then((resp) => {
                    this.productCategoryItems = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetProductByCateryId() {
            if (this.ProductCategoryIdval > 0) {
                this.$store
                    .dispatch("GetProductByCateryId", this.ProductCategoryIdval)
                    .then((resp) => {
                        this.productItems = resp;
                        this.GetPlanogramProductOrder();
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        checkresponse(resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            } else {
                this.ShowAlertError(resp, '')
            }
        },
    },
    mounted() {
        this.getproductcategory();
    },
};
</script>
  
<style>
table,
td,
th {
    border: 1px solid black;
}

table {
    width: 100%;
    font-size: 9pt;
    border-collapse: collapse;
}

.borderall {
    text-align: center;
    border: 3px solid #000;
}

.bordercol {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: thin solid #000;
}

.padbody {
    padding: 20px;
}

.padhead {
    padding: 5px;
    border: 3px solid #606060;
}

.pad {
    padding: 10px;
}

.bodytext {
    font-size: 9pt;
}
</style>
  