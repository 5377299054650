<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashboard</router-link
      >
      <span class="text-white"><i class="ni ni-bold-right"></i> SKU </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-group">
                      <label>Brand</label>
                      <select
                        v-model="ProductBrandIdval"
                        class="form-control"
                        required
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in productBrandItems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.BrandName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>SKU Name *</label>
                      <input
                        v-model="ProductNameval"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Unilever Case Price *</label>
                      <input
                        v-model="UnileverCasePriceval"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Unit Price *</label>
                      <input
                        v-model="UnitPriceval"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Recommended Price *</label>
                      <input
                        v-model="RecommendedPriceval"
                        class="form-control"
                        required
                      />
                    </div>
                    <hr />
                    <div class="text-center mb-3">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-inline">
              <div class="input-group">
                <input
                  v-model="searchval"
                  class="form-control"
                  @keyup="getsearch"
                  placeholder="Search..."
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    @click.prevent="getsearch"
                    class="btn btn-secondary"
                  >
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>SKU Category</th>
                    <th>SKU Brand</th>
                    <th>Unilever Case Price</th>
                    <th>Unit Price</th>
                    <th>Recommended Price</th>                   
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resultItems" :key="item.Id">
                    <td>{{ item.ProductName }}</td>
                    <td>{{ item.ProductCategoryName }}</td>
                    <td>{{ item.BrandName }}</td>
                    <td>{{ item.UnileverCasePrice }}</td>
                    <td>{{ item.UnitPrice }}</td>
                    <td>{{ item.RecommendedPrice }}</td>                    
                    <td>
                      <button
                        class="btn btn-secondary btn-sm my-2"
                        v-on:click="edititem(item.Id)"
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>

                      <button
                        class="btn btn-secondary btn-sm my-2"
                        v-on:click="opencompetitive(item.Id)"
                      >
                        <i class="fa fa-cubes"></i> Competitive
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "sKU",
  data() {
    return {
      Idval: 1,
      ProductBrandIdval: 0,
      ProductNameval: "",
      UnileverCasePriceval: "",
      UnitPriceval: "",
      RecommendedPriceval: "",
      productBrandItems: null,
      resultItems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none"
    };
  },
  methods: {
    newterm: function() {
      this.ProductCategoryIdval = 0;
      this.ProductBrandIdval = 0;
      this.ProductNameval = "";
      this.UnileverCasePriceval = "";
      this.RecommendedPriceval = "";
      this.UnitPriceval = "";
      this.searchval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function() {
      this.ProductCategoryIdval = 0;
      this.ProductBrandIdval = 0;
      this.ProductNameval = "";
      this.UnileverCasePriceval = "";
      this.RecommendedPriceval = "";
      this.UnitPriceval = "";
      this.searchval = "";
      this.openform = false;
      this.process = "N";
    },
    opencompetitive: function(Id) {
      localStorage.setItem("ProductId", Id);
      this.$router.push("/productcompetitor");
    },
    edititem: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getproductbyid", Id)
        .then(resp => {
          this.Idval = resp.Id;
          this.ProductBrandIdval = resp.ProductBrandId;
          this.ProductNameval = resp.ProductName;
          this.UnileverCasePriceval = resp.UnileverCasePrice;
          this.RecommendedPriceval = resp.RecommendedPrice;
          this.UnitPriceval = resp.UnitPrice;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    markproductpromo: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("markproductpromo", Id)
        .then(resp => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    markproductnpd: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("markproductnpd", Id)
        .then(resp => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    submitdata: function() {
      let Id = this.Idval;
      let ProductBrandId = this.ProductBrandIdval;
      let ProductName = this.ProductNameval;
      let UnileverCasePrice = this.UnileverCasePriceval;
      let UnitPrice = this.UnitPriceval;
      let RecommendedPrice = this.RecommendedPriceval;
      let Operation = this.process;
      let CreatedByUsername = this.$store.state.auth.token;
      if (ProductBrandId > 0) {
        let userdata = {
          Id,
          ProductBrandId,
          ProductName,
          UnileverCasePrice,
          UnitPrice,
          RecommendedPrice,
          Operation,
          CreatedByUsername
        };
        this.onloader = "flex";
        this.$store
          .dispatch("processproduct", userdata)
          .then(resp => {
            this.resultItems = resp;
            this.closeform();
            this.onloader = "none";
            swal({
              title: "Success!",
              text: "Submitted successfully",
              icon: "success",
              button: "Ok"
            });
          })
          .catch(err => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getproduct: function() {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getproduct")
        .then(resp => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function() {
      this.$store
        .dispatch("searchproduct", this.searchval)
        .then(resp => {
          this.resultItems = resp;
        })
        .catch(err => {
          console.log(err.response.data);
        });
    },
    getproductbrand: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getproductbrand")
        .then(resp => {
          this.productBrandItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    this.getproduct();
    this.getproductbrand();
  }
};
</script>

<style></style>
