<template>
    <div>
      <canvas ref="chart"></canvas>
    </div>
  </template>
  
  <script>
  import Chart from 'chart.js';
  
  export default {
    props: {
      chartData: {
        type: Object,
        required: true
      },
      options: {
        type: Object,
        default: () => ({})
      }
    },
    mounted() {
      this.renderChart();
    },
    watch: {
      chartData() {
        this.renderChart();
      }
    },
    methods: {
      renderChart() {
        if (this.chart) {
          this.chart.destroy();
        }
        this.chart = new Chart(this.$refs.chart, {
          type: 'bar',
          data: this.chartData,
          options: this.options
        });
      }
    },
    beforeDestroy() {
      if (this.chart) {
        this.chart.destroy();
      }
    }
  };
  </script>
  