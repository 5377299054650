<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard
      </router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i> MH SKUs </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-group">
                      <label>KPI Reference</label>
                      <select v-model="KpiReferenceIdval" class="custom-select">
                        <option value="0"></option>
                        <option v-for="item in KpiReferenceItems" :key="item.Id" v-bind:value="item.Id">
                          {{ item.KpiDescription }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="KpiReferenceIdval">
                      <label>Channel</label>
                      <select v-model="ChannelIdval" class="form-control" @change="getchannelproduct()" required>
                        <option value="0"></option>
                        <option v-for="item in channelData" :key="item.Id" v-bind:value="item.Id">
                          {{ item.ChannelName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="ChannelIdval">
                      <label>SKU *</label>
                      <select v-model="ProductIdval" class="form-control" required>
                        <option value="0"></option>
                        <option v-for="item in productItem" :key="item.Id" v-bind:value="item.Id">
                          {{ item.ProductName }}
                        </option>
                      </select>
                    </div>
                    <hr />
                    <div class="text-center mb-3" v-if="ProductIdval">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-inline">
              <div class="input-group">
                <input v-model="searchval" class="form-control" @keyup="getsearch" placeholder="Search..." />
                <div class="input-group-append">
                  <button type="button" @click.prevent="getsearch" class="btn btn-secondary">
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Channel</th>
                    <th>Promotion</th>
                    <th>Npd</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resultItems" :key="item.Id">
                    <td>{{ item.ProductName }}</td>
                    <td>{{ item.ChannelName }}</td>
                    <td>
                      <input type="checkbox" v-model="item.IsPromo" @change="SetChannelProductPromo(item.Id)" />
                    </td>
                    <td>
                      <input type="checkbox" v-model="item.IsNpd" @change="SetChannelProductNpd(item.Id)" />
                    </td>
                    <td>

                      <button class="btn btn-danger btn-sm" v-on:click="deleteitem(item.Id)">
                        <i class="fa fa-times"></i> Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import swal from "sweetalert";
export default {
  name: "SKU",
  data() {
    return {
      ProductIdval: 0,
      ChannelIdval: 0,
      KpiReferenceIdval: 0,
      productItem: null,
      resultItems: null,
      KpiReferenceItems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      channelData: state => state.app.channelData
    })
  },
  methods: {
    deleteitem(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("DeleteChannelProduct", Id)
        .then((resp) => {
          console.log(resp)
          this.getchannelproduct();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    SetChannelProductPromo(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("SetChannelProductPromo", Id)
        .then((resp) => {
          console.log(resp)
          this.getchannelproduct();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    SetChannelProductNpd(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("SetChannelProductNpd", Id)
        .then((resp) => {
          console.log(resp)
          this.getchannelproduct();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    submitdata() {
      let KpiReferenceId = this.KpiReferenceIdval
      let ChannelId = this.ChannelIdval;
      let ProductId = this.ProductIdval;
      let IsNpd = false;
      let IsPromo = false
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = { KpiReferenceId, ProductId, ChannelId, IsNpd, IsPromo, CreatedByUsername }
      this.onloader = "flex";
      this.$store
        .dispatch("processchannelproduct", userdata)
        .then((resp) => {
          console.log(resp)
          this.getchannelproduct();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.message);
        });
    },
    getchannelproduct() {
      // this.onloader = 'flex'
      let ChannelId = this.ChannelIdval
      let KpiRefenceId = this.KpiReferenceIdval
      let userdata = {
        ChannelId,
        KpiRefenceId
      }
      this.$store
        .dispatch("getchannelproduct", userdata)
        .then((resp) => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch() {
      let item = this.searchval;
      let ChannelId = this.ChannelIdval;
      let userdata = { item, ChannelId }
      this.$store
        .dispatch("searchchannelproduct", userdata)
        .then((resp) => {
          this.resultItems = resp;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    getproduct() {
      this.onloader = "flex";
      this.$store
        .dispatch("getproduct")
        .then((resp) => {
          this.productItem = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getkpireference() {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpireference")
        .then((resp) => {
          this.KpiReferenceItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
        });
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getkpireference();
    this.getproduct();
  },
};
</script>

<style></style>
