<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >

      <span class="text-white"><i class="ni ni-bold-right"></i> Store </span>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div>
          <div v-if="openform">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="my-2">
                      <img
                        v-if="uploadedimage"
                        height="100"
                        :src="uploadedimage"
                      />
                      <img
                        v-else
                        height="100"
                        src="img/brand/empty.png"
                      /><br />
                      <input
                        type="file"
                        id="file"
                        ref="file"
                        v-on:change="handleFileUpload()"
                      />
                      <br />
                      <button
                        type="button"
                        @click.prevent="submitFile"
                        class="btn btn-secondary"
                      >
                        <i class="fa fa-upload"></i>
                        Upload
                      </button>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Outlet Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="OutletNameval"
                            required
                          />
                        </div>
                      </div>                     
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Leveredge Outlet Code</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="LeverageOutletCodeval"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Channel</label>
                          <select v-model="ChannelIdval" class="form-control">
                            <option value="0"></option>
                            <option
                              v-for="item in channelData"
                              :key="item.Id"
                              v-bind:value="item.Id"
                            >
                              {{ item.ChannelName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Region</label>
                          <select
                            v-model="RegionIdval"
                            @change="getdistributor()"
                            class="form-control"
                            required
                          >
                            <option value="0"></option>
                            <option
                              v-for="item in regionItems"
                              :key="item.Id"
                              v-bind:value="item.Id"
                            >
                              {{ item.RegionName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Regional Sales Manager *</label>
                          <input
                            v-model="RegionalSalesManagerval"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Key Distributor Name</label>
                          <select
                            v-model="DistributorIdval"
                            class="form-control"
                            required
                          >
                            <option value="0"></option>
                            <option
                              v-for="item in distributorItem"
                              :key="item.Id"
                              v-bind:value="item.Id"
                            >
                              {{ item.DistributorName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Territory Manager Name *</label>
                          <input
                            v-model="TerritoryManagerNameval"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Phone Number *</label>
                          <input
                            type="tel"
                            v-model="PhoneNoval"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Location *</label>
                          <vue-google-autocomplete
                            v-model="address"
                            ref="address"
                            id="map"
                            classname="form-control"
                            placeholder="Please type your address"
                            v-on:placechanged="getAddressData"
                          >
                          </vue-google-autocomplete>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Dsr Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="DsrNameval"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Dsr Code</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="DsrCodeval"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="text-center mb-3">
                      <button
                        type="button"
                        @click.prevent="closeform"
                        class="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="form-inline mb-3">
              <button
                type="button"
                @click.prevent="newterm"
                class="btn btn-secondary"
              >
                New Outlet
              </button>
              <div class="input-group">
                <input
                  v-model="searchval"
                  class="form-control"
                  @keyup="getsearch"
                  placeholder="Search..."
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    @click.prevent="getsearch"
                    class="btn btn-secondary"
                  >
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive" v-if="resultItems">
              <table class=" table table-bordered table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th>Store Name</th>
                    <th>CMIH Code</th>
                    <th>Leveredge Store Code</th>
                    <th>Phone Number</th>
                    <th>Channel</th>
                    <th>Region</th>
                    <th>Region Sales Manager</th>
                    <th>Location</th>
                    <th>GeoCode</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resultItems" :key="item.Id">
                    <td>
                      <img
                        v-if="item.ImagePath"
                        :src="item.ImagePath"
                        height="30"
                        alt=""
                      />
                      <img
                        v-else
                        src="img/brand/empty.png"
                        height="30"
                        alt=""
                      />
                    </td>
                    <td>{{ item.OutletName }}</td>
                    <td>{{ item.OutletCode }}</td>
                    <td>{{ item.LeverageOutletCode }}</td>
                    <td>{{ item.PhoneNo }}</td>
                    <td>{{ item.ChannelName }}</td>
                    <td>{{ item.RegionName }}</td>
                    <td>{{ item.RegionalSalesManager }}</td>
                    <td>{{ item.Location }}</td>
                    <td>({{ item.Latitude }},{{ item.Longitude }})</td>
                    <td>
                      <button
                        class="btn btn-secondary my-2"
                        v-on:click="edititem(item.Id)"
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import swal from "sweetalert";
import axios from "axios";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  components: { VueGoogleAutocomplete },
  name: "Outlet",
  data() {
    return {
      Idval: 1,
      OutletNameval: "",
      OutletCodeval: "",
      LeverageOutletCodeval: "",
      ChannelIdval: 0,
      RegionIdval: 0,
      RegionalSalesManagerval: "",
      DistributorIdval: 0,
      TerritoryManagerNameval: "",
      PhoneNoval: "",
      Locationval: "",
      Longitudeval: "",
      Latitudeval: "",
      AddressDescriptionval: "",
      DsrNameval: "",
      DsrCodeval: "",
      ImagePathval: null,
      file: "",
      uploadedimage: "",
      address: "",
      resultItems: null,
      regionItems: null,
      distributorItem: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      channelData: (state) => state.app.channelData,
      regionData: (state) => state.app.regionData,
    }),
  },
  methods: {
    newterm: function() {
      this.OutletNameval = "";     
      this.LeverageOutletCodeval = "";
      this.ChannelIdval = 0;
      this.RegionIdval = 0;
      this.RegionalSalesManagerval = "";
      this.DistributorIdval = 0;
      this.TerritoryManagerNameval = "";
      this.PhoneNoval = "";
      this.Locationval = "";
      this.Longitudeval = "";
      this.Latitudeval = "";
      this.AddressDescriptionval = "";
      this.DsrNameval = "";
      this.DsrCodeval = "";
      this.ImagePathval = null;
      this.searchval = "";
      this.openform = true;
      this.process = "N";
      this.locatorButtonPressed();     
    },
    closeform: function() {
      this.OutletNameval = "";
      this.OutletCodeval = "";
      this.LeverageOutletCodeval = "";
      this.ChannelIdval = 0;
      this.RegionIdval = 0;
      this.RegionalSalesManagerval = "";
      this.Supervisorval = "";
      this.DistributorIdval = 0;
      this.TerritoryManagerNameval = "";
      this.PhoneNoval = "";
      this.Locationval = "";
      this.Longitudeval = "";
      this.Latitudeval = "";
      this.AddressDescriptionval = "";
      this.GeoCodeval = "";
      this.DsrNameval = "";
      this.DsrCodeval = "";
      this.ImagePathval = null;
      this.searchval = "";
      this.openform = false;
      this.process = "N";
    },
    getAddressData: function(addressData, placeResultData, id) {
      this.Longitudeval = addressData.longitude;
      this.Latitudeval = addressData.latitude;
      this.AddressDescriptionval = placeResultData.formatted_address;
      this.Locationval = placeResultData.formatted_address;
      console.log(id);
    },
    edititem: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getoutletbyid", Id)
        .then((resp) => {
          this.Idval = resp.Id;
          this.OutletNameval = resp.OutletName;         
          this.LeverageOutletCodeval = resp.LeverageOutletCode;
          this.ChannelIdval = resp.ChannelId;
          this.RegionIdval = resp.RegionId;
          this.RegionalSalesManagerval = resp.RegionalSalesManager;
          this.DistributorIdval = resp.DistributorId;
          this.TerritoryManagerNameval = resp.TerritoryManagerName;
          this.PhoneNoval = resp.PhoneNo;
          this.Locationval = resp.Location;
          this.Longitudeval = resp.Longitude;
          this.Latitudeval = resp.Latitude;
          this.AddressDescriptionval = resp.AddressDescription;
          this.DsrCodeval = resp.DsrCode;
          this.DsrNameval = resp.DsrName;
          if (resp.ImagePath != null) {
            this.uploadedimage =resp.ImagePath;
            }
          this.ImagePathval = resp.ImagePath;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    submitdata: function() {
      let Id = this.Idval;
      let OutletName = this.OutletNameval;    
      let LeverageOutletCode = this.LeverageOutletCodeval;
      let ChannelId = this.ChannelIdval;
      let RegionId = this.RegionIdval;
      let RegionalSalesManager = this.RegionalSalesManagerval;
      let DistributorId = this.DistributorIdval;
      let TerritoryManagerName = this.TerritoryManagerNameval;
      let PhoneNo = this.PhoneNoval;
      let Location = this.Locationval;
      let Longitude = this.Longitudeval;
      let Latitude = this.Latitudeval;
      let AddressDescription = this.AddressDescriptionval;
      let DsrName = this.DsrNameval;
      let DsrCode = this.DsrCodeval;
      let ImagePath = this.ImagePathval;
      let Operation = this.process;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        Id,
        OutletName,       
        LeverageOutletCode,
        ChannelId,
        RegionId,
        RegionalSalesManager,
        DistributorId,
        TerritoryManagerName,
        PhoneNo,
        Location,
        Longitude,
        Latitude,
        AddressDescription,
        DsrName,
        DsrCode,
        ImagePath,
        Operation,
        CreatedByUsername,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("processoutlet", userdata)
        .then((resp) => {
          this.resultItems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoutlet: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getoutlet")
        .then((resp) => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function() {
      this.$store
        .dispatch("searchoutlet", this.searchval)
        .then((resp) => {
          this.resultItems = resp;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    getregion: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getregion")
        .then((resp) => {
          this.regionItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getdistributor: function() {
      if (this.RegionIdval > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getdistributorbyregion", this.RegionIdval)
          .then((resp) => {
            this.distributorItem = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
    },
    submitFile: function() {
      this.onloader = "flex";
      var form = new FormData();
      let formalurl = this.ImagePathval;
      let doctype = "image";
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadfile", form)
        .then((resp) => {
          this.onloader = "none";
          this.ImagePathval = resp;
          this.uploadedimage = resp;
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    async getStreetAddressFrom(lat, long) {
      try {
         this.onloader = "flex";
        var { data } = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyDWijmMYqAgaaHeyYynqLoSaHpqRna_kOc"
        );
        if (data.error_message) {
          console.log(data.error_message);
           this.onloader = "none";
        } else {
          this.address = data.results[0].formatted_address;
          this.AddressDescriptionval = data.results[0].formatted_address;
          this.Locationval = data.results[0].formatted_address;
          this.Latitudeval = lat;
          this.Longitudeval = long;
          this.onloader = "none";
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getStreetAddressFrom(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getoutlet();
    this.getregion();
  },
};
</script>

<style></style>
