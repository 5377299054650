<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="pb-6">
        <Adminmenu> </Adminmenu>
      </div>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7" v-if="KpiReferenceItems">
      <div class="row">
        <div class="col-md-6">
          <div class="card my-3">
            <div class="card-body bg-secondary">
 <bar-chart :height="350" ref="barChart" :chart-data="reportBarChart.osaPerformance">
            </bar-chart>
            </div>
          </div>         
        </div>
        <div class="col-md-6">
          <div class="card my-3">
            <div class="card-body bg-secondary">
 <bar-chart ref="barChart" :chart-data="reportBarChart.npdPerformance">
            </bar-chart>
            </div>
          </div>          
        </div>
        <div class="col-md-6">
          <div class="card my-3">
            <div class="card-body bg-secondary">
 <bar-chart :height="350" ref="barChart" :chart-data="reportBarChart.perfectStore">
            </bar-chart>
            </div>
          </div>         
        </div>
        <div class="col-md-6">
          <div class="card my-3">
            <div class="card-body bg-secondary">
<bar-chart :height="350" ref="barChart" :chart-data="reportBarChart.outletVisitationPerformance" :width="600">
            </bar-chart>
            </div>
          </div>         
        </div>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>
<script>

import Adminmenu from "../Dashboardmenu/Adminmenu.vue";
import * as chartConfigs from '@/components/Charts/config';
import BarChart from '@/components/BarChart';
export default {
  components: {
    Adminmenu, BarChart
  },
  data() {
    return {
      reportBarChart: {
        osaPerformance: {
          labels: [],
          datasets: []
        },
        npdPerformance: {
          labels: [],
          datasets: []
        },
        perfectStore: {
          labels: [],
          datasets: []
        },
        outletVisitationPerformance: {
          labels: [],
          datasets: []
        },
        extraOptions: chartConfigs.blueChartOptions
      },
      loaded: false,
      KpiReferenceIdval: 0,
      resultKpiReference: null,
      KpiReferenceItems: null,
      onloader: "none",
    };
  },
  methods: {
    getuser() {
      this.$store
        .dispatch("getuser")
        .then(() => { })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    getkpireferencecurrent() {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpireferencecurrent")
        .then((resp) => {
          this.resultKpiReference = resp;
          this.KpiReferenceIdval = resp.Id
          this.GetOverviewReport()
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getkpireference() {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpireference")
        .then((resp) => {
          this.KpiReferenceItems = resp;
          this.getkpireferencecurrent();

          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
        });
    },
    GetDashboardGraph() {
      this.loaded = false
      this.onloader = "flex";
      this.$store
        .dispatch("GetDashboardGraph")
        .then((resp) => {
         
          //---------------Osa Graph-------------
          let osaLabel = resp.map(val => val.RegionName)
          let npdLabel = resp.map(val => val.RegionName)
          let perfectStoreLabel = resp.map(val => val.RegionName)
          

          let osaData = resp.map(val => val.OsaComplainPercentage)
          let osaGraph = {
            labels: osaLabel,
            datasets: [{label:'OSA Performance',barPercentage: 2,barThickness: 40,maxBarThickness: 40, minBarLength: 2, backgroundColor: ['#00bfff','#00ff40','#045957','#340459','#ff8000'], data: osaData }]
          }
          this.reportBarChart.osaPerformance = osaGraph;

          //---------------Npd Graph-------------
         
          let npdData = resp.map(val => val.NpdComplainPercentage)
          let npdGraph = {
            labels: npdLabel,
            datasets: [{label:'NPD Performance',barPercentage: 2,barThickness: 40,maxBarThickness: 40, minBarLength: 2, backgroundColor: ['#ff0080','#00ff40','#045957','#340459','#ff8000'], data: npdData }]
          }
          this.reportBarChart.npdPerformance = npdGraph;


          //---------------Perfect Store Graph-------------

        
          let perfectStoreData = resp.map(val => val.PerfectStore)
          let perfectStoreGraph = {
            labels: perfectStoreLabel,
            datasets: [{label:'Perfect Store',barPercentage: 2,barThickness: 40,maxBarThickness: 40, minBarLength: 2, backgroundColor: ['#ffbf00','#00ff40','#045957','#340459','#ff8000'], data: perfectStoreData }]
          }
          this.reportBarChart.perfectStore = perfectStoreGraph;

          this.onloader = "none";
          this.loaded = true
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
        });
    },
    GetDashboardVisitationGraph() {
      this.loaded = false
      this.onloader = "flex";
      this.$store
        .dispatch("GetDashboardVisitationGraph")
        .then((resp) => {
         
          let outletVisitationPerformanceLabel = ["Outlet Scheduled","Outlet Scored","Outlet Not Scored"]
          let outletVisitationPerformanceData = [resp.TotalOutlet,resp.TotalOutletScore,resp.TotalOutletNotScore]
          let outletVisitationPerformanceGraph = {
            labels: outletVisitationPerformanceLabel,
            datasets: [{ label: "Visitation Score Performance",barPercentage: 2,barThickness: 40,maxBarThickness: 40, minBarLength: 2, backgroundColor: ['#045957','#340459','#ff8000'], data: outletVisitationPerformanceData }]
          }         

          this.reportBarChart.outletVisitationPerformance = outletVisitationPerformanceGraph;

          this.onloader = "none";
          this.loaded = true
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
        });
    },
  },
  mounted() {
    this.getuser();
    this.getkpireference();
    this.GetDashboardGraph();
    this.GetDashboardVisitationGraph();
  },
};
</script>

<style></style>
