<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> Distributor
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-group">
                      <label>Distributor *</label>
                      <input
                        v-model="DistributorNameval"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Phone Number *</label>
                      <input
                        v-model="PhoneNumberval"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Email Address *</label>
                      <input
                        v-model="EmailAddressval"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Region</label>
                      <select
                        v-model="RegionIdval"
                        class="form-control"
                        required
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in regionItem"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.RegionName }}
                        </option>
                      </select>
                    </div>
                    <hr />
                    <div class="text-center mb-3">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-inline">
              <div class=" input-group">
                <input
                  v-model="searchval"
                  class="form-control"
                  @keyup="getsearch"
                  placeholder="Search..."
                />
                <div class=" input-group-append">
                  <button
                    type="button"
                    @click.prevent="getsearch"
                    class="btn btn-secondary"
                  >
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>
            <div class=" table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Distributor</th>
                    <th>Phone Number</th>
                    <th>Email Address</th>
                    <th>Region</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in resultItems" :key="item.Id">
                    <td>{{ item.DistributorName }}</td>
                    <td>{{ item.PhoneNumber }}</td>
                    <td>{{ item.EmailAddress }}</td>
                    <td>{{ item.RegionName }}</td>
                    <td>
                      <button
                        class="btn btn-secondary btn-sm my-2"
                        v-on:click="edititem(item.Id)"
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "Distributor",
  data() {
    return {
      Idval: 1,
      DistributorNameval: "",
      PhoneNumberval: "",
      EmailAddressval: "",
      RegionIdval: 0,
      regionItem: null,
      resultItems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  methods: {
    newterm: function() {
      this.DistributorNameval = "";
      this.PhoneNumberval = "";
      this.EmailAddressval = "";
      this.RegionIdval = 0;
      this.searchval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function() {
      this.DistributorNameval = "";
      this.PhoneNumberval = "";
      this.EmailAddressval = "";
      this.RegionIdval = 0;
      this.searchval = "";
      this.openform = false;
      this.process = "N";
    },
    edititem: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getdistributorbyid", Id)
        .then((resp) => {
          this.Idval = resp.Id;
          this.DistributorNameval = resp.DistributorName;
          this.PhoneNumberval = resp.PhoneNumber;
          this.EmailAddressval = resp.EmailAddress;
          this.RegionIdval = resp.RegionId;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    submitdata: function() {
      let Id = this.Idval;
      let DistributorName = this.DistributorNameval;
      let PhoneNumber = this.PhoneNumberval;
      let EmailAddress = this.EmailAddressval;
      let RegionId = this.RegionIdval;
      let Operation = this.process;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        Id,
        DistributorName,
        PhoneNumber,
        EmailAddress,
        RegionId,
        Operation,
        CreatedByUsername,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("processdistributor", userdata)
        .then((resp) => {
          this.resultItems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getdistributor: function() {
      // this.onloader = 'flex'
      this.$store
        .dispatch("getdistributor")
        .then((resp) => {
          this.resultItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getregion: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getregion")
        .then((resp) => {
          this.regionItem = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function() {
      this.$store
        .dispatch("searchdistributor", this.searchval)
        .then((resp) => {
          this.resultItems = resp;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getdistributor();
    this.getregion();
  },
};
</script>

<style></style>
