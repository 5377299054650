<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <router-link to="/user" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Users</router-link
      >
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> Merchandiser Outlet
      </span>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-6">
            <div class="card bg-secondary border-0 mb-0">
              <div class=" card-header">
                <h3 class=" card-title">Available Outlet</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-md-4 col"
                    v-for="item in resultNotAssignedItems"
                    :key="item.Id"
                  >
                    <div class="card bg-dark">
                       <div class="card-body text-center">
                        <h1 class="text-white">{{ item.OutletName }}</h1>
                        <h3 class="text-white">{{ item.OutletCode }}</h3>
                        <h5 class="text-white">{{ item.RegionName }}</h5>
                        <h6 class="text-white">{{ item.Location }}</h6>
                        <hr />
                        <button
                          class="btn btn-danger btn-sm my-2"
                          v-on:click="assigned(item.Id)"
                        >
                          <i class="fa fa-share"></i> Assign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-header">
                <h3 class=" card-title">Assigned Outlet</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-md-4 col"
                    v-for="item in resultAssignedItems"
                    :key="item.Id"
                  >
                    <div class="card bg-success">
                      <div class="card-body text-center">
                        <h1 class="text-white">{{ item.OutletName }}</h1>
                        <h3 class="text-white">{{ item.OutletCode }}</h3>
                        <h5 class="text-white">{{ item.RegionName }}</h5>
                        <h6 class="text-white">{{ item.Location }}</h6>
                        <hr />
                        <button
                          class="btn btn-danger btn-sm my-2"
                          v-on:click="deletemerchandiseroutlet(item.Id)"
                        >
                          <i class="fa fa-times"></i> Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  name: "MerchandiserOutlet",
  data() {
    return {
      UserIdval: localStorage.getItem("UserId") || "",
      resultNotAssignedItems: null,
      resultAssignedItems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  methods: {
    deletemerchandiseroutlet: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("deletemerchandiseroutlet", Id)
        .then(() => {
          this.getoutletnotassigned();
          this.getoutletassigned();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },
    assigned: function(Id) {
      let OutletId = Id;
      let UserId = this.UserIdval;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        OutletId,
        UserId,
        CreatedByUsername,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("processmerchandiseroutlet", userdata)
        .then(() => {
          this.getoutletnotassigned();
          this.getoutletassigned();
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoutletnotassigned: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getoutletnotassigned", this.UserIdval)
        .then((resp) => {
          this.resultNotAssignedItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoutletassigned: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getmerchandiseroutlet", this.UserIdval)
        .then((resp) => {
          this.resultAssignedItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getoutletnotassigned();
    this.getoutletassigned();
  },
};
</script>

<style></style>
