<template>
  <div class="flex-column-reverse">
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary shadow border-0">
            <div class="card-body">
              <div class="text-center text-muted my-3">
                <router-link to="/login" class="nav-link">
                  <img src="img/brand/green.png" height="50"
                /></router-link>
                <h3>PERFECT STORE MERCHANDISING</h3>
                <h5 class="mt-3">Login with your account Credential</h5>
              </div>
              <form role="form" @submit.prevent="userlogin" autocomplete="off">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Username"
                  addon-left-icon="ni ni ni-single-02"
                  v-model="username" autocomplete='off'
                >
                </base-input>
                <base-input
                  class="input-group-alternative"
                  placeholder="Password"
                  type="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="password" autocomplete='off'
                >
                </base-input>
                <div class="mb-3 mx-3">
                  <router-link to="/retrievepassword" class="text-primary"
                    ><small>Forgot password?</small></router-link
                  >
                </div>
                <base-checkbox class="custom-control-alternative">
                  <span class="text-muted">Remember me</span>
                </base-checkbox>
                <div class="text-center">
                  <button type="submit" class="btn btn-danger btn-block my-3">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
export default {
    name: 'login',
    components: {},
    data() {
        return {
            username: '',
            password: '',
            rememberMe:true,
            errormsg: '',
            onloader: 'none'
        }
    },
    methods: {
         userlogin: function () {
            let username = this.username
            let password = this.password  
            let RememberMe=this.rememberMe         
            this.onloader = 'flex'
            let userdata = {
                username,
                password,
                RememberMe
            }           
            this.$store.dispatch('login', userdata)
                .then(() => {                       
                    this.onloader = 'none'
                    this.$router.push('/dashboard');
                })
                .catch(err => {                                   
                    this.password = ''
                    this.onloader = 'none'
                    if (err.response.data === "Yet to Logon") {
                        this.$router.push('/changepassword')
                    } else {
                        swal({
                            title: "Oops!",
                            text: err.response.data,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                })
        },
        getroles: function () {           
            this.$store.dispatch('getroles')
                .then(() => {
                                 
                })
                .catch(err => {
                    this.onloader = 'none'
                   console.log(err)
                })
        },
        getchannel: function () {           
            this.$store.dispatch('getchannel')
                .then(() => {
                                 
                })
                .catch(err => {
                    this.onloader = 'none'
                   console.log(err)
                })
        },
        getregion: function () {           
            this.$store.dispatch('getregion')
                .then(() => {
                                 
                })
                .catch(err => {
                    this.onloader = 'none'
                   console.log(err)
                })
        },
       
    },
    mounted() {      
         this.getroles();  
         this.getchannel();        
    }
}
</script>

<style>
</style>
