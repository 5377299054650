<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> Password Policy
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-body">
            <div class="form-horizontal">
              <form role="form" @submit.prevent="submitdata">
                <div class="form-group">
                  <label>Must Contain Upper Case </label>
                  <select
                    v-model="MustContainUpperCaseval"
                    class="form-control"
                    required
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Must Contain Number </label>
                  <select
                    v-model="MustContainNumberval"
                    class="form-control"
                    required
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Must Contain Special Character </label>
                  <select
                    v-model="MustContainSpecialCharacterval"
                    class="form-control"
                    required
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Password Length *</label>
                  <input
                    v-model="PasswordMinimumLengthval"
                    class="form-control" type="number" min="1"
                    required
                  />
                </div>
                <hr />
                <div class="text-center mb-3">
                  <button type="submit" class="btn btn-primary my-4">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  name: "sKU",
  data() {
    return {
      MustContainUpperCaseval: false,
      MustContainSpecialCharacterval: false,
      MustContainNumberval: false,
      PasswordMinimumLengthval: '',
      resultItems: null,
      onloader: "none",
    };
  },
  methods: {
    getpasswordpolicy() {
      this.onloader = "flex";
      this.$store
        .dispatch("getpasswordpolicy")
        .then((resp) => {
          if (resp != null) {
            this.MustContainUpperCaseval = resp.MustContainUpperCase;
            this.MustContainSpecialCharacterval =
              resp.MustContainSpecialCharacter;
            this.MustContainNumberval = resp.MustContainNumber;
            this.PasswordMinimumLengthval = resp.PasswordMinimumLength;
          }
           this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.checkresponse(err.message);
        });
    },

    submitdata() {
      let MustContainUpperCase = this.MustContainUpperCaseval;
      let MustContainSpecialCharacter = this.MustContainSpecialCharacterval;
      let MustContainNumber = this.MustContainNumberval;
      let PasswordMinimumLength = this.PasswordMinimumLengthval;
      let CreatedByUsername = this.$store.state.auth.token;
      let userdata = {
        MustContainUpperCase,
        MustContainSpecialCharacter,
        MustContainNumber,
        PasswordMinimumLength,
        CreatedByUsername,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("processpasswordpolicy", userdata)
        .then((resp) => {
          this.resultItems = resp;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },

    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getpasswordpolicy();
  },
};
</script>

<style></style>
