<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> Outlet Visit Permission</span
      >
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <form role="form" @submit.prevent="PermitOutletVisit">
                    <div class="form-group">
                      <span>KPI Index Details</span>
                      <select
                        v-model="KpiReferenceIdval"
                        class="form-control"
                        @change="GetAllOutletVisitPermission()"
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in KpiReferenceItems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.KpiDescription }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="KpiReferenceIdval">
                      <span>Merchandiser</span>
                      <select
                        v-model="MerchandiserIdval"
                        class="form-control"
                        @change="GetOutletYetPermitedforVisitByMerchandiser()"
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in MerchandiserItems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.FullName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="MerchandiserIdval">
                      <span>Outlet</span>
                      <select v-model="OutletIdval" class="form-control">
                        <option value="0"></option>
                        <option
                          v-for="item in OutletItems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.OutletName }}
                        </option>
                      </select>
                    </div>
                    <div class="text-center" v-if="OutletIdval">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <div class="justify-content-end form-inline">
                    <base-input
                      class="input-group-alternative"
                      placeholder="Search here..."
                      addon-left-icon="fa fa-search"
                      v-model="searchval"
                    >
                    </base-input>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Outlet Name</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in searchedResultQuery" :key="item.Id">
                          <td>{{ item.OutletName }}</td>
                          <td>{{ item.DateCreated }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>
<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "permitoutvisit",
  data() {
    return {
      KpiReferenceIdval: "",
      MerchandiserIdval: "",
      OutletIdval: "",
      OutletItems: null,
      KpiReferenceItems: null,
      MerchandiserItems: null,
      ReportItems: null,
      currdate: null,
      searchval:'',
      onloader: "none",
    };
  },
computed:{
  searchedResultQuery() {
      if (this.searchval) {
        return this.ReportItems.filter((item) => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.OutletName.toLowerCase().includes(v) 
            );
        });
      } else {
        return this.ReportItems;
      }
    },
},
  methods: {
    getkpireference: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getkpireference")
        .then((resp) => {
          this.KpiReferenceItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    GetOutletYetPermitedforVisitByMerchandiser: function () {
      let KpiReferenceId = this.KpiReferenceIdval;
      let MerchandiserId = this.MerchandiserIdval;
      if ((KpiReferenceId > 0) & (MerchandiserId > 0)) {
        this.onloader = "flex";
        this.$store
          .dispatch("GetOutletYetPermitedforVisitByMerchandiser", {
            MerchandiserId,
            KpiReferenceId,
          })
          .then((resp) => {
            this.OutletItems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
            this.$store.dispatch("logout");
            this.$router.push("/login");
          });
      }
    },
    GetAllOutletVisitPermission: function () {
      let KpiReferenceId = this.KpiReferenceIdval;
      if (KpiReferenceId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("GetAllOutletVisitPermission", this.KpiReferenceIdval)
          .then((resp) => {
            this.ReportItems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
            this.$store.dispatch("logout");
            this.$router.push("/login");
          });
      }
    },
    GetAllMerchandiser: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("GetAllMerchandiser")
        .then((resp) => {
          this.MerchandiserItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    PermitOutletVisit: function () {
      let KpiReferenceId = this.KpiReferenceIdval;
      let OutletId = this.OutletIdval;
      if ((KpiReferenceId > 0) & (OutletId > 0)) {
        this.onloader = "flex";
        this.$store
          .dispatch("PermitOutletVisit", { KpiReferenceId, OutletId })
          .then((resp) => {
            this.ReportItems = resp;
            console.log(resp);
            this.GetOutletYetPermitedforVisitByMerchandiser();
            this.onloader = "none";
            swal({
              title: "Success!",
              text: "Permited Successfully",
              icon: "success",
              button: "Ok",
            });
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
            this.$store.dispatch("logout");
            this.$router.push("/login");
          });
      }
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    this.getkpireference();
    this.GetAllMerchandiser();
  },
};
</script>

<style>
table,
td,
th {
  border: 1px solid black;
}
table {
  width: 100%;
  font-size: 9pt;
  border-collapse: collapse;
}

.borderall {
  text-align: center;
  border: 3px solid #000;
}

.bordercol {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  border: thin solid #000;
}

.padbody {
  padding: 20px;
}

.padhead {
  padding: 5px;
  border: 3px solid #606060;
}

.pad {
  padding: 10px;
}

.bodytext {
  font-size: 9pt;
}
</style>
